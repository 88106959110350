export default function EventLoading() {
  return (
    <div class="p-4 max-w-sm w-[300px] mx-auto">
      <div class="animate-pulse flex flex-col space-y-4">
        <div class="rounded-lg bg-gray-300 h-[320px] w-[300px]"></div>

        <div class="flex-1 space-y-4 py-1 w-[250px]">
          <div class="h-4 bg-gray-300 rounded w-3/4"></div>
          <div class="space-y-2">
            <div class="h-4 bg-gray-300 rounded"></div>
            <div class="h-4 bg-gray-300 rounded w-5/6"></div>
          </div>
        </div>

        <div class="h-9 bg-gray-300 rounded w-[300px]"></div>
      </div>
    </div>
  );
}
