import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../utility/api";

const initialState = {
  events: [],
  eventsBackup: [],
  eventDetail: {},
  loading: false,
  error: "",
  message: "",
  status: "",
};
export const getEvents = createAsyncThunk("getEvents", async () => {
  try {
    const response = await api().get("/events");
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

export const getEventById = createAsyncThunk("getEventById", async (id) => {
  try {
    const response = await api().get(`/event/${id}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
});
export const addEvent = createAsyncThunk("addEvent", async (data) => {
  try {
    const response = await api().post("/event", data, {
      withCredentials: true,
    });
    return { response: response.data };
  } catch (err) {
    console.log(err);
  }
});
export const deleteEvent = createAsyncThunk("deleteEvent", async (data) => {
  try {
    const response = await api().delete(`/event/${data.id}`, {
      withCredentials: true,
    });
    return { response: response.data, id: data.id };
  } catch (err) {
    console.log(err);
  }
});
export const updateEvent = createAsyncThunk("updateEvent", async (data) => {
  try {
    const response = await api().put("/event", data, { withCredentials: true });
    console.log(response);
    return { response: response.data, data };
  } catch (err) {
    console.log(err);
  }
});
export const getEventsByFilter = createAsyncThunk(
  "getEventsByFilter",
  async (data) => {
    let query = "/events/filter?";
    for (const key in data) {
      if (Object.keys(data).indexOf(`${key}`) !== 0) {
        query = query + "&";
      }
      query = query + `${key}=${data[key]}`;
    }
    try {
      const response = await api().get(query);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

const event = createSlice({
  name: "event",
  initialState,
  reducers: {
    clearStatus(state) {
      state.status = "";
    },
    getEventsByDistrict(state, action) {
      state.events = state.events.filter((e) => e.district == action.payload);
    },
    getEventByPrice(state, action) {
      let status;
      var paid = action.payload.paid;
      var free = action.payload.free;
      var online = action.payload.online;

      if (!paid && free && !online) {
        //"sadece ücretsiz"
        state.events = state.eventsBackup;
        status = "sadece ücretsiz";
        state.events = state.events.filter((e) => e.price == 0);
      } else if (paid && !free && !online) {
        //"sadece ücretli"
        state.events = state.eventsBackup;
        status = "sadece ücretli";
        state.events = state.events.filter((e) => e.price !== 0);
      } else if (!paid && !free && online) {
        //"sadece online"
        state.events = state.eventsBackup;
        status = "sadece online";
        state.events = state.events.filter((e) => e.address == "Online");
      } else if (!paid && free && online) {
        //"ücretsiz online"
        state.events = state.eventsBackup;
        status = "ücretsiz online";
        state.events = state.events.filter(
          (e) => e.address == "Online" || e.price === 0
        );
      } else if (paid && !free && online) {
        //"ücretli online"
        state.events = state.eventsBackup;
        status = "ücretli online";
        state.events = state.events.filter(
          (e) => e.address == "Online" || e.price !== 0
        );
      } else {
        //"tüm seçenekler işaretli ya da işaretsiz"
        //console.log(state.eventsBackup);
        state.events = state.eventsBackup;
      }

      // state.events = state.events.filter(e =>

      //     (
      //         isOnlyFree == 1 &&
      //         e.price == 0

      //     )
      //     ||
      //     (
      //         isOnlyFree == 0
      //         && e.price !== 0)
      // &&

      // (
      //     online
      //     && e.address == "Online"
      // )
      //)

      // if (!action.payload.paid && action.payload.free) {
      //     console.log("sadece ücretsiz from action ")
      //     state.events = state.eventsBackup;
      //     state.events = state.events.filter(e => e.price == 0);
      // } else if (action.payload.paid && !action.payload.free) {
      //     console.log("sadece ücretli from action")
      //     state.events = state.eventsBackup;
      //     state.events = state.events.filter(e => e.price !== 0);
      //     console.log(state.events);
      // }
      // else {

      //     console.log("diğer seçenek from action");
      //     console.log(state.eventsBackup);
      //     state.events = state.eventsBackup;
      // }
    },
    getEventsByOnlineStatus(state, action) {
      if (action.payload.online) {
        //console.log("sadece onlineler")
        //state.events = state.eventsBackup;
        state.events = state.events.filter((e) => e.address == "Online");
      } else {
        //console.log("online farketmez hepsi");
        state.events = state.eventsBackup;
      }
    },
  },
  extraReducers: (builder) => {
    //GET
    builder.addCase(getEvents.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.events = action.payload;
      state.eventsBackup = action.payload;
    });
    builder.addCase(getEvents.rejected, (state, action) => {
      state.loading = false;
      state.error = "get getEvents error";
    });
    builder.addCase(getEventById.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getEventById.fulfilled, (state, action) => {
      state.loading = false;
      state.eventDetail = action.payload;
    });
    builder.addCase(getEventById.rejected, (state, action) => {
      state.loading = false;
      state.error = "get getElementByID error";
    });
    builder.addCase(getEventsByFilter.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getEventsByFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.events = action.payload;
      state.eventsBackup = action.payload;
    });

    //POST, DELETE ,PUT

    builder.addCase(addEvent.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(addEvent.fulfilled, (state, action) => {
      const message = action.payload.response?.message;
      state.message = message !== undefined ? message : " ";
      state.loading = false;
      state.status = action.payload.response.status;
    });
    builder.addCase(addEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteEvent.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteEvent.fulfilled, (state, action) => {
      if (action.payload.response.status == "success") {
        state.loading = false;
        state.events = state.events.filter((e) => e.id !== action.payload.id);
        const message = action.payload.response.message;
        state.message = message !== undefined ? message : " ";
      }
    });
    builder.addCase(deleteEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = "get deleteEvent error ";
    });
    builder.addCase(updateEvent.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateEvent.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.events.findIndex(
        (e) => e.id === action.payload.data.id
      );
      state.events[index] = action.payload.data;
      state.status = action.payload.response.status;
    });
    builder.addCase(updateEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = "get updateEVent Error";
    });
  },
});
export const { getEventByPrice, clearStatus } = event.actions;
export default event.reducer;
