import "./style.css";
import EventCart from "../eventCart/EventCart";
import EventLoading from "../EventLoading";
export default function Events({ data, loading }) {
  return (
    <>
      {loading ? (
        loading ? (
          <div id="events-container">
            <EventLoading />
            <EventLoading />
            <EventLoading />
          </div>
        ) : (
          <div id="events-container">
            {data?.map((item) => {
              return <EventCart item={item} />;
            })}
          </div>
        )
      ) : (
        <div id="events-container">
          {data?.map((item) => {
            return <EventCart item={item} />;
          })}
        </div>
      )}
    </>
  );
}
