import { useDispatch, useSelector } from "react-redux"
import { getEvents } from "../../../redux/eventSlice";
import Events from "../../../components/events/Events";
import { useEffect } from "react";
import './home.css'
import banner from "./../../../assets/banner2.jpg";
import { useNavigate } from "react-router-dom";
import ScrollGift from "../../../components/Gifts/scrollGift/ScrollGift";
import LoaderGift from "../../../components/Gifts/loaderGift/LoaderGift";
import { getPopularEvents } from "../../../redux/popularSlice";
import { api } from "../../../utility/api";
import { googleAuth } from "../../../redux/authSlice";
export default function () {
    const dispatch = useDispatch();
    const events = useSelector(state => state.popular.popularEvents);
    const loading = useSelector(state => state.popular.loading);

    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getPopularEvents());

    }, [])

    const handleBtnRegisterClick = () => {
        navigate('/register');
    }

    
    return (
        <>
            <div id="banner">
                <img id="banner-img" src={banner}></img>
                <div id="banner-content">
                    <div id="b-text">
                        <h2 id="b-title">
                            ETKİNLİK DÜNYASI
                        </h2>
                        <p>Dünyanın dört bir yanında gerçekleşen etkinlikleri bir araya   {/*<br></br> */}
                            getiren dinamik bir platformdur. Amacımız, etkinlik dünyasını {/*<br></br>*/}
                            keşfetmeyi daha kolay ve keyifli hale getirerek insanları {/*<br></br>*/}
                            etkinliklerle buluşturmaktır.
                        </p>
                    </div>
                    <div id="b-buttons">
                        <button id="b-button-register" onClick={handleBtnRegisterClick}>Kayıt Ol</button>
                    </div>
                </div>
            </div>
            <ScrollGift />
            <h2 id="populerEventsTitle">Popüler Etkinlikler</h2>
            {
                loading
                    ? <div id="loaderWrapper"><LoaderGift color={"rgb(250, 95, 39)"} /></div>
                    : <Events data={events} />
            }
        </>
    )
}