import { Link } from "react-router-dom";
import IntersectionObserverImage from "../IntersectionObserverImage";
import MomentDate from "../MomentDate";
import "./style.css";
import PlaceIcon from "@mui/icons-material/Place";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { getImageUrl } from "../../utility/getImageUrl";
export default function EventCart({ item }) {
  return (
    <div key={item?.id} id="cart">
      <IntersectionObserverImage
        url={getImageUrl(item.imgUrl)}
        id={"cart-img"}
      />
      <div id="cart-body">
        <h3 id="cart-title">{item.name}</h3>
        <div>
          <CalendarMonthIcon sx={{ color: "lightgray", width: 20 }} />
          <p>
            <MomentDate date={item.eventDate} />
          </p>
        </div>
        <div id="cart-price">
          {item.price == 0 ? "Ücretsiz" : item.price + " TL"}
        </div>
        <div id="cart-address">
          <PlaceIcon sx={{ color: "lightgray", width: 20 }} />
          {item.address == "Online" ? (
            <p>Online</p>
          ) : (
            <p>
              {item.city} / {item.district}
            </p>
          )}
        </div>
      </div>
      <Link id="cart-detail" to={`/event/${item.id}`}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Başvuru ve Detaylar</span>
          <KeyboardArrowRightIcon sx={{ display: "block" }} />
        </div>
      </Link>
    </div>
  );
}
